import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plan-warning-modal',
  templateUrl: './plan-warning-modal.component.html',
  styleUrls: ['./plan-warning-modal.component.scss']
})
export class PlanWarningModalComponent implements OnInit {

  @Input() message: string;
  @Input() message1: string;
  @Input() planLimit: number;
  @Input() userData: any;
  @Input() paymentData: any;
  @Input() id: any;
  @Input() entity_type: any;
  @Input() entity_slug: any;

  transaction_result: any = '';
  transaction_id: any = '';
  articleCallBackUrl: any = '';
  mediaCallBackUrl: any = '';
  general_settings:any;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private metaTagService: Meta,
    private title: Title
  ) {
    this.articleCallBackUrl = environment.oneTimeArticleCallBackUrl;
    this.mediaCallBackUrl = environment.oneTimeMediaCallBackUrl;
    let general_settings = localStorage.getItem("general_settings");
    if (!general_settings) { return; }
    this.general_settings = JSON.parse(general_settings);
    this.title.setTitle(this.general_settings.meta_title);
    this.metaTagService.updateTag({ property: 'og:title', content: this.general_settings.meta_title });
    this.metaTagService.updateTag({ property: "og:keywords", content: this.general_settings.meta_keyword, });
    this.metaTagService.updateTag({ property: "og:description", content: this.general_settings.meta_description });
    this.metaTagService.updateTag({ name: 'keywords', content: this.general_settings.meta_keyword, });
    this.metaTagService.updateTag({ name: 'description', content: this.general_settings.meta_description });
    this.metaTagService.updateTag({ property: 'robots', content: "follow,index" });
  }

  ngOnInit(): void {
    this.spinner.hide();
  }

  initializePayment() {
    this.spinner.show();
    var data:any = {
      amount: this.paymentData.price,
      currency: this.paymentData.currency,
      entity_slug: this.entity_slug,
      entity_type: this.entity_type,
    }
    if(this.entity_type==1){
      data.callback = this.articleCallBackUrl
    }
    else{
      data.callback = this.mediaCallBackUrl
    }
    
    localStorage.setItem( 'oneTimePurchaseData',JSON.stringify(data))
    this.paymentService.getOnetimePaymentRequest(data).subscribe({
      next: (response: any) => {
        this.spinner.hide();
        if (response.status == 'success') {
          if (response.data != '') {
            if (response.data.original.data) {
              window.location = response.data.original.data.url;
            }
          }
        } else {
          this.toastr.error(response.message);
        }
      },
      error: (error: any) => {
        this.toastr.error(error.message);
      }
    })
  }
}
