import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private baseUrl: string;
  authToken: any = '';
  languageCode: any = JSON.stringify("EN");
  country_id: any = JSON.stringify("98");
  subscription: Subscription;
  source = interval(1000);

  paginationStringReturn(data: any) {
    return (
      'page=' +
      data.page +
      '&limit=' +
      data.limit +
      '&sort_by=' +
      data.sort_by +
      '&sub_category_id=' +
      data.sub_category_id +
      '&from_date=' +
      data.from_date +
      '&to_date=' +
      data.to_date +
      '&search_string=' +
      data.search_string
    );
  }

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
    const languageCode = localStorage.getItem('language');
    this.languageCode = languageCode !== null ? languageCode : this.languageCode;
    const country_id = localStorage.getItem('country_id');
    this.country_id = country_id !== null ? country_id : this.country_id;
  }

  createAuthorizationHeader() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Language-Code', this.languageCode);
    headers = headers.append('Country-Id', this.country_id);
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  createForDataAuthorizationHeader() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Language-Code', this.languageCode);
    headers = headers.append('Country-Id', this.country_id);
    return headers;
  }

  get(url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.get<any>(`${this.baseUrl}/${url}`, { headers: headers });
  }

  getById(id: number, url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.get<any>(`${this.baseUrl}/${url}/${id}`, {
      headers: headers,
    });
  }

  getwithdata(data: any, url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.get<any>(
      `${this.baseUrl}/${url}?` + this.paginationStringReturn(data),
      { headers: headers }
    );
  }

  getWithCountry(data: any, url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.get<any>(
      `${this.baseUrl}/${url}?` + 'country_name=' + data.country_name,
      { headers: headers }
    );
  }

  post(data: any, url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.post<any>(`${this.baseUrl}/${url}`, data, {
      headers: headers,
    });
  }
  postWithData(data: any, url: string) {
    const headers = this.createForDataAuthorizationHeader();
    return this.http.post<any>(`${this.baseUrl}/${url}`, data, {
      headers: headers,
    });
  }

  postwithID(data: any, id: number, url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.post<any>(`${this.baseUrl}/${url}/${Number(id)}`, data, {
      headers: headers,
    });
  }

  put(data: any, id: number, url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.put<any>(`${this.baseUrl}/${url}/${Number(id)}`, data, {
      headers: headers,
    });
  }

  delete(id: number, url: string) {
    const headers = this.createAuthorizationHeader();
    return this.http.delete<any>(`${this.baseUrl}/${url}/${Number(id)}`, {
      headers: headers,
    });
  }

}
