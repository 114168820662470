<div class="modal-content">
    <div class="modal-body donation-modal-main">
        <form [formGroup]="donationForm" novalidate="novalidate">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                    <div class="modal-top-new-class">
                        <h4>Donate</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                    <label for="first_name">First name <span style="color:red">*</span></label>
                    <input type="text" formControlName="first_name" class="form-control">
                    <div style="color:red" *ngIf="fval['first_name'].touched && fval['first_name'].invalid">
                        <small>First name is required.</small>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                    <label for="last_name">Last name</label>
                    <input type="text" formControlName="last_name" class="form-control">
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                    <label for="doner_email">Email address <span style="color:red">*</span></label>
                    <input type="email" formControlName="doner_email" class="form-control">
                    <div style="color:red" *ngIf="fval['doner_email'].touched && fval['doner_email'].invalid">
                        <small>
                            <div *ngIf="fval['doner_email'].errors && fval['doner_email'].errors['required']">Email is
                                required.
                            </div>
                            <div *ngIf="fval['doner_email'].errors && fval['doner_email'].errors['email']">Please,
                                enter valid email address.
                            </div>
                        </small>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                    <label for="country_id">Country <span style="color:red">*</span></label>
                    <div class="select-donation">
                        <select placeholder="Country" formControlName="country_id" (change)="getCurrency()" class="form-control" autocomplete="off">
                            <option *ngFor="let country of countryList" [value]="country.country_id">
                                {{country.country_name}}</option>
                        </select>
                    </div>
                    <div style="color:red" *ngIf="fval['country_id'].touched && fval['country_id'].invalid">
                        <small>Country is required.</small>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                    <label for="last_name">Contact number</label>
                    <input type="number" min="0" formControlName="doner_contact" class="form-control">
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                    <label for="amount">Amount ( {{currency_code}} )<span style="color:red">*</span></label>
                    <input type="number" min="0" formControlName="amount" class="form-control">
                    <div style="color:red" *ngIf="fval['amount'].touched && fval['amount'].invalid">
                        <div *ngIf="fval['amount'].errors && fval['amount'].errors['required']">Amount is required.
                        </div>
                        <div *ngIf="fval['amount'].errors && fval['amount'].errors['min']">Invalid amount</div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-2 mt-2 text-center">
                    <button type="button" (click)="initializePayment()" class="btn blue-btn submit">Donate now</button>
                </div>
            </div>
        </form>
    </div>
</div>