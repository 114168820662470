import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  @Input() message: string;
  constructor(
    public modal: NgbActiveModal,
    private router: Router,
    public translate: TranslateService
  ) {
    let language = localStorage.getItem("language");
    this.translate.setDefaultLang(language !== null ? language.toLowerCase() : "en");
   }

  ngOnInit(): void {
  }

  closeModal() {
    this.modal.dismiss('Cross click');
    var urlString = window.location.href;
    if (urlString.indexOf("member-signup") > -1) {
      this.router.navigate([`auth/login`]).then(() => {
      });
    } else if (urlString.indexOf("login") > -1) {
      this.router.navigate([`auth/login`]).then(() => {
      });
    } else {
      this.router.navigate([`users/dashboard`]).then(() => {
      });
    }
  }

}
