<div class="modal-content">
  <div class="modal-body">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-2 mb-3">
        <!-- <img src="assets/images/checkmark.png">   -->
        <h4>{{'Get_full_access_to_this_item'|translate}} <a type="button" class="close float-end" data-dismiss="modal"
            (click)="modal.dismiss('close')"><i class="ri-close-line"></i></a></h4>
      </div>
      <!-- <div class="col-12 mb-3 text-center p-4 pb-0">
        <div class="row">
          <div [ngClass]="planLimit>=0 && paymentData.length==0?'col-12':'col-6'">
            <div class="box-full-access-css">
              <h6 [innerHTML]="message | translate"></h6>
              <div *ngIf="planLimit>=0">
                <a href="javascript:void(0);" type="button" class="btn blue-btn" data-dismiss="modal"
                  (click)="modal.dismiss('upgrade')">{{'Upgrade_the_plan_now'|translate}}</a>
              </div>
              <div *ngIf="planLimit<=0">
                <button href="javascript:void(0);" type="button" class="btn blue-btn"
                  (click)="modal.close('open')">{{'Open'|translate}}</button>
              </div>
            </div>
          </div>
          <div class="col-6" [ngClass]="planLimit>=0 && paymentData.length==0?'d-none':''">
            <div class="box-full-access-css">
              <h6>{{message1}}</h6>
              <h5>{{paymentData.currency}} {{ paymentData.price}}</h5>
              <div *ngIf="planLimit>=0">
                <a href="javascript:void(0);" type="button" class="btn blue-btn" (click)="initializePayment()">{{'PURCHASE_NOW'|translate}}</a>
              </div>
              <div *ngIf="planLimit<=0">
                <a href="javascript:void(0);" type="button" class="btn blue-btn" data-dismiss="modal"
                  (click)="modal.dismiss('upgrade')">{{'Upgrade_the_plan_now'|translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-12 mb-3 text-center p-4 pb-0">
        <div class="row">
          <div [ngClass]="planLimit==0 && paymentData.length==0?'col-12':'col-6'">
            <div class="box-full-access-css">
              <h6 [innerHTML]="message | translate"></h6>
              <div *ngIf="planLimit==0">
                <a href="javascript:void(0);" type="button" class="btn blue-btn" data-dismiss="modal"
                  (click)="modal.dismiss('upgrade')">{{'Upgrade_the_plan_now'|translate}}</a>
              </div>
              <div *ngIf="planLimit!=0">
                <button href="javascript:void(0);" type="button" class="btn blue-btn"
                  (click)="modal.close('open')">{{'Open'|translate}}</button>
              </div>
            </div>
          </div>
          <div class="col-6" [ngClass]="planLimit==0 && paymentData.length==0?'d-none':''">
            <div class="box-full-access-css">
              <h6>{{message1}}</h6>
              <h5>{{paymentData.currency}} {{ paymentData.price}}</h5>
              <div *ngIf="planLimit==0">
                <a href="javascript:void(0);" type="button" class="btn blue-btn"
                  (click)="initializePayment()">{{'PURCHASE_NOW'|translate}}</a>
              </div>
              <div *ngIf="planLimit!=0">
                <a href="javascript:void(0);" type="button" class="btn blue-btn" data-dismiss="modal"
                  (click)="modal.dismiss('upgrade')">{{'Upgrade_the_plan_now'|translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>