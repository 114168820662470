import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private http: HttpClient, private commonService: CommonService) { }

  getCountryList(): Observable<any> {
    return this.commonService.get('countryList');
  }

  getStateList(data:{}): Observable<any> {
    return this.commonService.post(data,'statesList');
  }

  postRegistrationForm(data:{}): Observable<any> {
    return this.commonService.postWithData(data,'registerUser');
  }
  
  getVerifyAccount(data:{}): Observable<any> {
    return this.commonService.post(data,'verifyAccount');
  }

  getLoginUser(data:{}): Observable<any> {
    return this.commonService.postWithData(data,'authentication');
  }

  getProfileData(): Observable<any> {
    return this.commonService.get('profile');
  }

  getProfileUpdate(data:{}): Observable<any> {
    return this.commonService.postWithData(data,'update_profile');
  }
}
