import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient, private commonService: CommonService) { }

  
  getPaymentRequest(data:{}): Observable<any> {
    return this.commonService.postWithData(data, 'paymentRequest');
  }

  getsubscriptionPlansList(): Observable<any> {
    return this.commonService.get('SubscriptionPlans');
  }

  getsubscriptionDetailsList(data:{}): Observable<any> {
    return this.commonService.post(data, 'subscriptionDetails');
  }

  currencyList(data:{}): Observable<any> {
    return this.commonService.postWithData(data, 'currencyList');
  }

  //Donation 
  postDonationRequest(data:{}): Observable<any> {
    return this.commonService.post(data, 'addDonation');
  }
  
  getDonationRequest(data:{}): Observable<any> {
    return this.commonService.postWithData(data, 'donationPaymentRequest');
  }

  //membership 
  getMembershipRequest(data:{}): Observable<any> {
    return this.commonService.post(data, 'change_plan_payments');
  }
  
  postMembershipRequest(data:{}): Observable<any> {
    return this.commonService.postWithData(data, 'plan_post_payments');
  }

  //onetime
  getOnetimePaymentRequest(data:{}): Observable<any> {
    return this.commonService.post(data, 'onetime/paymentRequest');
  }
  
  postOnetimePaymentRequest(data:{}): Observable<any> {
    return this.commonService.postWithData(data, 'onetime/add');
  }

  //Product payment 
  getProductPaymentRequest(data:{}): Observable<any> {
    return this.commonService.postWithData(data, 'product_payments');
  }
  
  getProductPostPayments(data:{}): Observable<any> {
    return this.commonService.postWithData(data, 'product_post_payments');
  }
  //PlanPostPayments
  getPlanPostPayments(data:{}): Observable<any> {
    return this.commonService.post(data, 'one_time_purchase');
  }

  // Common services
  getProfileData(): Observable<any> {
    return this.commonService.get('profile');
  }
}
