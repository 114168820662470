<div class="modal-content">
    <div class="modal-body">
      <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-12 thank-you-sign-up text-center">
              <!-- <img src="assets/images/checkmark.png"> -->
              <h4>{{message}}</h4>
              <a href="javascript:void(0);" data-dismiss="modal" (click)="closeModal()">Close</a>
              <a href="javascript:void(0);" class="btn-danger" (click)="deleteModal()">Remove</a>
          </div>
      </div>
    </div>
  </div>