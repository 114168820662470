import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/services/payment.service';
import { SignupService } from 'src/app/services/signup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-donation-modal',
  templateUrl: './donation-modal.component.html',
  styleUrls: ['./donation-modal.component.scss']
})
export class DonationModalComponent implements OnInit {
  donationForm: FormGroup;
  countryList: any = [];
  country_name: any = 'India';
  donationCallBackUrl: string = '';
  currency_id: any = '';
  currency_code: any = '';

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private signupService: SignupService,
    private paymentService: PaymentService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    ) {
    const userCountry = localStorage.getItem('country_name');
    this.country_name = userCountry !== null ? userCountry : this.country_name;
    this.donationCallBackUrl = environment.donationCallBackUrl;
    let language = localStorage.getItem("language");
    this.translate.setDefaultLang(language !== null ? language.toLowerCase() : "en");
  }

  ngOnInit(): void {
    this.loadForm();
    this.getCountryList();
  }

  loadForm() {
    this.donationForm = this.fb.group({
      first_name: ['', Validators.compose([Validators.required]),],
      last_name: [''],
      doner_email: ['', Validators.compose([Validators.required, Validators.email]),],
      country_id: ['', Validators.compose([Validators.required]),],
      doner_contact: ['',],
      amount: ['', Validators.compose([Validators.required]),],
    })
  }

  get fval() {
    return this.donationForm.controls;
  }

  getCountryList() {
    this.signupService.getCountryList().subscribe({
      next: (response: any) => {
        this.spinner.hide();
        if (response.status == 'success') {
          this.countryList = response.data;
          if (this.country_name) {
            let selectedCountry = this.countryList.filter((x: any) => x.country_name == this.country_name)
            this.donationForm.controls['country_id'].setValue(selectedCountry[0].country_id);
            this.getCurrency();
          }
          this.cdr.detectChanges();
        } else {
          this.toastr.error(response.message);
        }
      },
      error: (error: any) => {
        this.toastr.error(error.message);
      }
    })
  }

  getCurrency() {
    let data = {
      country_id: this.donationForm.value.country_id
    }
    this.paymentService.currencyList(data).subscribe({
      next: (response: any) => {
        this.spinner.hide();
        if (response.status == 'success') {
          let currency = response.data;
          if (currency) {
            this.currency_id = currency[0].currency_code;            ;
            this.currency_code = currency[0].currency_symbol;
            this.cdr.detectChanges();
          }
        }
      }
    })
  }

  initializePayment() {
    if (this.donationForm.valid) {
      this.spinner.show();
      const formData: any = new FormData();
      formData.append("doner_name", this.donationForm.value.first_name + " " + this.donationForm.value.last_name);
      formData.append("doner_email", this.donationForm.value.doner_email);
      formData.append("amount", this.donationForm.value.amount);
      formData.append("country_id", this.donationForm.value.country_id);
      formData.append("doner_contact", this.donationForm.value.doner_contact);
      formData.append("callback", this.donationCallBackUrl);
      formData.append("currency", this.currency_id);
      let data = {
        doner_email: this.donationForm.value.doner_email,
        doner_fullname: this.donationForm.value.first_name + " " + this.donationForm.value.last_name,
        country_id:this.donationForm.value.country_id,
        amount: this.donationForm.value.amount,
        currency: this.currency_code,
        doner_contact: this.donationForm.value.doner_contact
      }
      localStorage.setItem("DonationFormData", JSON.stringify(data));
      this.paymentService.getDonationRequest(formData).subscribe({
        next: (response: any) => {
          this.spinner.hide();
          if (response.status == 'success') {
            if (response.data != '') {
              if (response.data.original.data) {
                window.location = response.data.original.data.url;
              }
            }
          } else {
            this.toastr.error(response.message);
          }
        },
        error: (error: any) => {
          this.toastr.error(error.message);
          this.spinner.hide();
        }
      })
    } else {
      this.donationForm.markAllAsTouched();
      this.toastr.error(this.translate.instant('Enter_all_required_fields'));
    }
  }

}
