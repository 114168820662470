import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { CryptoService } from "../services/crypto.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor( private router: Router, private cryptoService: CryptoService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // // add authorization header with jwt token if available
    // let userData = this.cryptoService.decryptIdValue('userData', 'global-well-app');
    let userData = localStorage.getItem('globalWellLoginDetails');
    if (userData != undefined) {
      let currentUser = userData !== undefined ? JSON.parse(userData) : userData;
      if (currentUser && currentUser.token) {
        request = request.clone({
          setHeaders: {
            Authorization: `${'Bearer ' + currentUser.token}`,
          },
        });
      }
    }

    return next.handle(request);
    // .pipe(
    //   catchError((error) => {
    //     // this.authenticationService.logout();
    //     if(error.status == 401){
    //       localStorage.clear()
    //       // this.router.navigate(['/auth/login']);
    //     }
    //       return throwError(error.statusText);
    //   })
    // );
  }
}
