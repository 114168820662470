import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  constructor() {}

  encryptIdValue(val:any, cookiename:any, key:any) {
    var encryption = CryptoJS.AES.encrypt(JSON.stringify(val), key).toString();
    this.setCookie(cookiename, encryption, 365);
  };

  decryptIdValue(cookiename:any, key:any) {
    var val = this.getCookie(cookiename);
    if (val != "") {
      var decryption = CryptoJS.AES.decrypt(val, key).toString(
        CryptoJS.enc.Utf8
      );
      return decryption;
    }
    return undefined;
  };

  getCookie(cname:any) {
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(cname) == 0) {
        return c.split("=")[1];
      }
    }
    return "";
  }

  setCookie(cname:any, cvalue:any, exdays:any) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  }

  clearCookie(name:any) {
    this.setCookie(name, new Object(), 0);
  }
}
