// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v1.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  // apiUrl: 'http://127.0.0.1/global-well-laravel/public/api',
  apiUrl: 'https://api.global-well.com/api',
  callBackUrl: 'https://www.global-well.com/member-signup',
  membershipCallBackUrl: 'https://www.global-well.com/memberships',
  oneTimeArticleCallBackUrl: 'https://www.global-well.com/articles',
  oneTimeMediaCallBackUrl: 'https://www.global-well.com/media',
  donationCallBackUrl: 'https://www.global-well.com/philontropy-and-donations',
  productCallBackUrl: 'https://www.global-well.com/shop',
  billingCallBackUrl: 'https://www.global-well.com/billing-address',
  country_iso_code:'in',
  appThemeName: 'Global-Well',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
